import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormPreset = lazy(
  () => import("./form-preset-B_CUkcIP.js").then((module) => ({
    default: module.FormPreset
  }))
);
function FormPreset({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormPreset, { ...props });
}
const useFormPresetContent = () => {
  return useMemo(
    () => ({
      formPreset: {
        component: FormPreset
      }
    }),
    []
  );
};
export {
  LazyFormPreset as FormPreset,
  useFormPresetContent
};
